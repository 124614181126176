import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';
import { type Locale } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { switchMap } from 'rxjs';
import { assertAvailableLanguages, AvailableLanguages } from '@camino-solutions/core/i18n';
import localeHu from '@angular/common/locales/hu';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeHu, 'hu');

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'cs-portal-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  readonly #primengConfig = inject(PrimeNGConfig);
  readonly #translocoService = inject(TranslocoService);

  constructor() {
    this.#primengConfig.ripple = true;
    this.#primengConfig.inputStyle.set('outlined');

    // Subscribe to language changes
    this.#translocoService.langChanges$
      .pipe(
        switchMap(lang => {
          assertAvailableLanguages(lang);
          this.setLocale(lang);
          return this.#translocoService.selectTranslateObject('PRIMENG');
        }),
      )
      .subscribe(translation => this.#primengConfig.setTranslation(translation));

    // // Example usage
    // const scheduler = new TaskScheduler();
    //
    // const initialTasks = [
    //   { taskId: 'task1', delay: 3000 },
    //   { taskId: 'task2', delay: 5000 },
    // ];
    //
    // scheduler.addTasks(initialTasks);
    //
    // // Adding more tasks after some delay
    // setTimeout(() => {
    //   const moreTasks = [
    //     { taskId: 'task3', delay: 2000 },
    //     { taskId: 'task4', delay: 4000 },
    //   ];
    //   scheduler.addTasks(moreTasks);
    // }, 7000); // 7 seconds delay to allow initial tasks to complete
    //
    // // Removing a task after some delay
    // setTimeout(() => {
    //   // scheduler.removeTasks(['task1']);
    // }, 1000);
  }

  private setLocale(lang: AvailableLanguages) {
    this.getLocale(lang).then(locale => {
      setDefaultOptions({ locale });

      console.info('Language changed to:', lang);
    });
  }

  private getLocale(lang: AvailableLanguages): Promise<Locale> {
    const dateFnsImport = import('date-fns/locale');

    let result: Promise<Locale> = dateFnsImport.then(m => m.hu);
    switch (lang) {
      case 'en':
        result = dateFnsImport.then(m => m.enUS);
        break;
    }

    return result;
  }
}

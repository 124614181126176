import type { AppMenuItem } from '@camino-solutions/core/app/ui/app-menu';
import { environment } from '@camino-solutions/core/environment';
import { pageFlowCreateRouteGenerator } from '@camino-solutions/core/page-flow/router';

export const menu: readonly AppMenuItem[] = Object.freeze([
  {
    label: 'Irányítópult',
    icon: 'pi pi-chart-line',
    routerLink: ['/'],
    items: [
      {
        label: 'Diagramok',
        icon: 'pi pi-chart-line',
        routerLink: ['/'],
      },
    ],
  },
  {
    label: 'Cég',
    icon: 'pi pi-building',
    items: [
      {
        label: 'Létrehozás',
        icon: 'pi pi-plus-circle',
        routerLink: [pageFlowCreateRouteGenerator('Cég')],
      },
    ],
  },
  {
    label: 'Projekt',
    icon: 'pi pi-briefcase',
    items: [
      {
        label: 'Létrehozás',
        icon: 'pi pi-plus-circle',
        routerLink: [pageFlowCreateRouteGenerator('project')],
      },
    ],
  },
  {
    label: 'Dokumentum',
    icon: 'pi pi-users',
    items: [
      {
        label: 'Létrehozás',
        icon: 'pi pi-plus-circle',
        routerLink: [pageFlowCreateRouteGenerator('document')],
      },
    ],
  },
  ...(['development', 'local-docker'].indexOf(environment.name) > -1
    ? [
        {
          label: 'Előfizetés',
          icon: 'pi pi-users',
          items: [
            {
              label: 'Fix',
              items: [
                {
                  label: 'Új',
                  icon: 'pi pi-user-plus',
                  routerLink: ['/subscriptions/fixed/new'],
                },
                {
                  label: 'Listázás',
                  icon: 'pi pi-bars',
                  routerLink: ['/subscriptions/fixed'],
                },
              ],
            },
            {
              label: 'Rugalmas',
              items: [
                {
                  label: 'Listázás',
                  icon: 'pi pi-bars',
                  routerLink: ['/subscriptions/flexible'],
                },
              ],
            },
          ],
        },
      ]
    : []),
  // {
  //   label: 'Test',
  //   // accessRule: TestRule.instance,
  //   items: [
  //     {
  //       label: 'Test form',
  //       routerLink: ['/testform'],
  //       // accessRule: TestRule.instance,
  //     },
  //   ],
  // },
  // {
  //   label: 'Session panel',
  //   items: [
  //     {
  //       label: 'Debug',
  //       routerLink: ['/session-test-panel'],
  //     },
  //   ],
  // },
]);
